<template>
  <div>
    <header-top-dashboard primary></header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col cols="12" class="mt-n3">
          <paginated-table
            create-new-item-label="Create New Manufacturer"
            :headers="headers"
            :items="items"
            :pagination-details="paginationDetails"
            @createNewItem="createNewItem"
            @editItem="editItem"
            @fetchNextPage="fetchDataByUrl(paginationDetails.nextPageUrl)"
            @fetchPreviousPage="
              fetchDataByUrl(this.paginationDetails.previousPageUrl)
            "
            @updateItemsPerPage="updateItemsPerPage"
            @updateItemStatus="updateItemStatus"
            @updateSelectedPage="updateSelectedPage"
          ></paginated-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";
import PaginatedTable from "@/components/Tables/PaginatedTable.vue";

export default {
  name: "manufacturers",
  components: {
    HeaderTopDashboard,
    PaginatedTable,
  },
  data() {
    return {
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Contact Number", value: "contactNumber" },
        { text: "Type", value: "type" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      items: [],
      fullItems: [],
      paginationDetails: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        pageStart: 0,
        pageStop: 0,
        itemsLength: 0,
        firstPageUrl: "",
        lastPageUrl: "",
        nextPageUrl: "",
        previousPageUrl: "",
        links: [],
        path: "",
      },
    };
  },
  computed: {
    ...mapStores(useSessionStore),
  },
  methods: {
    createNewItem() {
      this.$router.push("/manufacturers/create");
    },

    editItem(item) {
      const originalItem = this.fullItems.find(
        (element) => (element.id = item.id)
      );

      if (!originalItem) {
        this.$notify.error(
          "An error occurred while preparing to modify this manufacturer. Please try again later or contact support."
        );
        return;
      }

      this.$router.push({
        name: "Edit Manufacturer",
        params: {
          id: item.id,
        },
      });
    },

    fetchDataByUrl(url) {
      if (!url) {
        // TODO - print alert.
        return;
      }

      this.paginationDetails.loading = true;

      this.$http
        .get(url, this.sessionStore.getHttpConfig)
        .then((result) => {
          this.items.splice(0);

          for (let i = 0; i < result.data.data.length; i++) {
            const manufacturer = result.data.data[i];
            this.fullItems.push(manufacturer);
            const obj = {
              id: manufacturer.id,
              name: manufacturer.name,
              type: manufacturer.type,
              status: manufacturer.status,
            };

            if (
              manufacturer.contact_details &&
              manufacturer.contact_details.length > 0
            ) {
              obj.contactNumber = `${manufacturer.contact_details[0].country_code}${manufacturer.contact_details[0].number}`;
            }

            this.items.push(obj);
          }

          this.setPaginationDetails(result.data);
        })
        .catch((error) => {
          this.handleHttpError(
            error,
            "An error occurred while loading data. Please try again or contact support."
          );
        })
        .finally(() => {
          this.paginationDetails.loading = false;
        });
    },

    updateItemStatus(item, status) {
      const body = {
        status,
      };

      this.$http
        .patch(
          `/manufacturers/${item.id}`,
          body,
          this.sessionStore.getHttpConfig
        )
        .then(() => {
          this.fetchDataByUrl("/manufacturers");
          this.$notify.success("Status updated successfully.");
        })
        .catch((error) => {
          this.handleHttpError(
            error,
            "An error occurred while updating the status."
          );
        });
    },

    updateItemsPerPage(newValue) {
      const searchParams = new URLSearchParams({
        size: newValue,
      });

      const url = `${this.paginationDetails.path}?${searchParams.toString()}`;

      this.fetchDataByUrl(url);
    },
  },
  mounted() {
    this.fetchDataByUrl("/manufacturers");
  },
};
</script>
